
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import "../node_modules/bootstrap/scss/bootstrap.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Calibri', Roboto, "Helvetica Neue", sans-serif !important; font-size: 0.9em !important; }
table {
  width: 100%;
  border-radius: 8px !important;
  font-family: 'Calibri' !important;
}

table tr td {
  vertical-align: top !important;
  text-align: left !important;
  padding-right: 36px !important;
  vertical-align: middle !important;
  font-family: 'Calibri' !important;
  font-size: 0.9em !important;
}

table tr td .actions {
  visibility: hidden;
}

table tr:hover td .actions {
  visibility: visible;
}

table tr:hover td .actions .fas:hover {
  color: #414042;
}

body { margin: 0; font-family: "Rajdhani"; }
.custom-dialog-container .mat-dialog-container{
    padding: 0px !important;
}

.custom-dialog-container-without-bg .mat-dialog-container{
  padding: 0px !important;
  background-color: transparent;
}

.mat-progress-bar-fill::after {
  background-color: #414042 !important;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: #414042 !important;
 }

// .mat-form-field-underline {
//   /*change color of underline*/
//   background-color: #414042 !important;
// } 

.mat-select {
  color: #414042 !important;
  font-family: 'Calibri' !important;
}

.mat-option {
  color: #414042 !important;
  font-family: 'Calibri' !important;
  font-size: 0.9em !important;
}

.mat-form-field-ripple {
 /*change color of underline when focused*/
 background-color: #414042 !important;;
}
.mat-option.mat-active {
  color: #414042 !important;
  font-weight: 850 !important;
}

mat-dialog {
  z-index: 9999 !important;
}

mat-form-field{
    width: 100%;
    font-size: 1em;
    font-family: 'Calibri' !important;
}

.success-snackbar {
  background-color: $white !important;
  border-bottom: 3px solid $success;
}

.info-snackbar {
  background-color: $white !important;
  border-bottom: 3px solid $info;
}

.warning-snackbar {
  background-color: $white !important;
  border-bottom: 3px solid $warning;
}

.danger-snackbar {
  background-color: $white !important;
  border-bottom: 3px solid $danger;
}

.mat-spinner circle {
  stroke: #6c757d !important;
}
